const mongoose = require("mongoose");
const Schema = mongoose.Schema;

const citySchema = new Schema({
    Description: {
        type: String,
        required: true,
    },
    DescriptionRu: {
        type: String,
    },
    Ref: {
        type: String,
        required: true,
    },
    Area: {
        type: String,
        required: true,
    },
});

module.exports = mongoose.model("City", citySchema);
