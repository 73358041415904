const config = {
    //url to be used in link generation
    // url: "http://localhost:3000",
    url: "https://le-vi.com.ua",
    // url: "https://dev.le-vi.com.ua",
    // mongodb connection settings
    database: {
        // db: "mongodb+srv://Dmitriy:dihDic8uDRRnN96@cluster0.ugxj1.mongodb.net/levi",
        db: "mongodb://127.0.0.1:27017/levi",
        // db: "mongodb://127.0.0.1:27017/devlevi",
    },
    liqPay: {
        // public_key: "sandbox_i78689704969",
        public_key: "i34692056010",
        // private_key: "sandbox_B36ByiXrkbOThZc3sJU7weJalemp3aNflkv9kX6w",
        private_key: "ohJWlU2BDXPI0WoEKjocHoVTecftYqmZKlxzCoi8",
    },
    // server details
    server: {
        port: "3000",
    },
    // server: {
    //     port: "3020",
    // },
    dsn: {
        host: "mail.new-life.com",
        port: 587,
        auth: {
            user: "le-vi@le-vi.com.ua",
            pass: "FT9E9SDdkT",
        },
    },
    from: "le-vi@le-vi.com.ua",
    to: "manager@le-vi.com.ua",
    // to: "dwwwwww87@gmail.com",
    toForOrder: "sale.levi.com.ua@gmail.com",
    // toForOrder: "dwwwwww87@gmail.com",
    subject: "Заявка из формы",
    keyPost: "61a2758240dc2502fb5337bf8707755f",
    secret: "secretnumbre7843957forleviauthsecret",
    jwtExpiresIn: "90d",
};

module.exports = config;
