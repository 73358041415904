const mongoose = require("mongoose");
const Schema = mongoose.Schema;

const warehouseSchema = new Schema({
    Description: {
        type: String,
    },
    DescriptionRu: {
        type: String,
    },
    CityRef: {
        type: String,
    },
    SettlementDescription: {
        type: String,
    },
});

module.exports = mongoose.model("Warehouse", warehouseSchema);
