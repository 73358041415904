const mongoose = require("mongoose");
const Schema = mongoose.Schema;

const regionSchema = new Schema({
    Description: {
        type: String,
        required: true,
    },
    DescriptionRu: {
        type: String,
        required: true,
    },
    Ref: {
        type: String,
        required: true,
    },
    updateAt: {
        type: Date,
        default: Date.now,
    },
});

module.exports = mongoose.model("Region", regionSchema);
